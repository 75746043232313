import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: function () {
        return import('./views/Home.vue');
      },
    },
    {
      path: '/download',
      name: 'Download',
      component: function () {
        return import('./views/Download.vue');
      },
      meta: {
        breadcrumb: 'Download',
      },
    },
    {
      path: '/pages',
      name: 'Pages',
      component: function () {
        return import('./views/Pages.vue');
      },
      meta: {
        breadcrumb: 'Pages',
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: function () {
            return import('./views/pages/Dashboard.vue');
          },
          meta: {
            breadcrumb: 'Dashboard',
          },
        },
        {
          path: 'manage-certificates',
          name: 'Manage Certificates',
          component: function () {
            return import('./views/pages/ManageCertificates.vue');
          },
          meta: {
            breadcrumb: 'Manage Certificates',
          },
        },
        {
          path: 'land-title-correction',
          name: 'Land Title Correction',
          component: function () {
            return import('./views/pages/LtCorrection.vue');
          },
          meta: {
            breadcrumb: 'Land Title Correction',
          },
        },
        {
          path: 'create-package',
          name: 'Create Package',
          component: function () {
            return import('./views/pages/CreatePackage.vue');
          },
          meta: {
            breadcrumb: 'Create Package',
          },
        },
        {
          path: 'view-all-packages',
          name: 'View All Packages',
          component: function () {
            return import('./views/pages/ViewAllPackages.vue');
          },
          meta: {
            breadcrumb: 'View All Packages',
          },
        },
        {
          path: 'add-applications-to-package',
          name: 'Add Applications to Package',
          component: function () {
            return import('./views/pages/AddApplications.vue');
          },
          meta: {
            breadcrumb: 'Add Applications to Package',
          },
        },
        {
          path: 'add-applications-simple',
          name: 'Add Applications - Simple',
          component: function () {
            return import('./views/pages/AddApplicationsSimple.vue');
          },
          meta: {
            breadcrumb: 'Add Applications - Simple',
          },
        },
        {
          path: 'fee-details',
          name: 'Fee Details',
          component: function () {
            return import('./views/pages/FeeDetails.vue');
          },
          meta: {
            breadcrumb: 'Fee Details',
          },
        },
        {
          path: 'title-searches/purchase',
          name: 'Title Seaches',
          component: function () {
            return import('./views/pages/title-searches/PurchaseTitle.vue');
          },
          meta: {
            breadcrumb: 'Purchase Title',
          },
        },
        {
          path: 'title-searches/manage',
          name: 'Title Seaches',
          component: function () {
            return import('./views/pages/title-searches/ManagePurchases.vue');
          },
          meta: {
            breadcrumb: 'Manage Purchases',
          },
        },
        {
          path: 'searches-stc/purchase',
          name: 'Searches & STCs',
          component: function () {
            return import('./views/pages/searches-stc/SearchesSTC.vue');
          },
          meta: {
            breadcrumb: 'Searches & STCs',
          },
        },
        {
          path: 'searches-stc/review',
          name: 'Review',
          component: function () {
            return import('./views/pages/searches-stc/SearchesSTCReview.vue');
          },
          meta: {
            breadcrumb: 'Review',
          },
        },
        {
          path: 'searches-stc/manage-existing',
          name: 'Manage Existing',
          component: function () {
            return import('./views/pages/searches-stc/SearchesSTCExisting.vue');
          },
          meta: {
            breadcrumb: 'Manage existing',
          },
        },
        {
          path: 'info-and-history',
          name: 'Info & History',
          component: function () {
            return import('./views/pages/InfoAndHistory.vue');
          },
          meta: {
            breadcrumb: 'Info & History',
          },
        },
        {
          path: 'package-deferred',
          name: 'Package Deferred',
          component: function () {
            return import('./views/pages/PackageDeferred.vue');
          },
          meta: {
            breadcrumb: 'Package Deferred',
          },
        },
        {
          path: 'package-pending',
          name: 'Package Pending',
          component: function () {
            return import('./views/pages/PackagePending.vue');
          },
          meta: {
            breadcrumb: 'Package Pending',
          },
        },
        {
          path: 'package-validation',
          name: 'Package Validation',
          component: function () {
            return import('./views/pages/PackageValidation.vue');
          },
          meta: {
            breadcrumb: 'Package Validation',
          },
        },
        {
          path: 'request-review',
          name: 'Request Review',
          component: function () {
            return import('./views/pages/RequestReview.vue');
          },
          meta: {
            breadcrumb: 'Request Review',
          },
        },
        {
          path: 'submit-package',
          name: 'Submit Package',
          component: function () {
            return import('./views/pages/SubmitPackage.vue');
          },
          meta: {
            breadcrumb: 'Submit Package',
          },
        },
        {
          path: 'send-to-efs',
          name: 'Send to EFS',
          component: function () {
            return import('./views/pages/SendToEfs.vue');
          },
          meta: {
            breadcrumb: 'Send to EFS',
          },
        },
        {
          path: 'submit-package-defected',
          name: 'Submit Package - Defected',
          component: function () {
            return import('./views/pages/SubmitPackageDefected.vue');
          },
          meta: {
            breadcrumb: 'Submit Package - Defected',
          },
        },
        {
          path: 'withdraw',
          name: 'Withdraw',
          component: function () {
            return import('./views/pages/Withdraw.vue');
          },
          meta: {
            breadcrumb: 'Withdraw',
          },
        },
        {
          path: 'applications',
          name: 'Applications',
          component: function () {
            return import('./views/pages/Applications.vue');
          },
          meta: {
            breadcrumb: 'Applications',
          },
        },
        {
          path: 'applications-esigned',
          name: 'Applications E-signed',
          component: function () {
            return import('./views/pages/ApplicationsEsigned.vue');
          },
          meta: {
            breadcrumb: 'Applications E-signed',
          },
        },
        {
          path: 'applications-defected',
          name: 'Applications Defected',
          component: function () {
            return import('./views/pages/ApplicationsDefected.vue');
          },
          meta: {
            breadcrumb: 'Applications Defected',
          },
        },
        {
          path: 'edit-application/claimant',
          name: 'Claimant',
          component: function () {
            return import('./views/pages/edit-application/claim/Claimant.vue');
          },
          meta: {
            breadcrumb: 'Claimant',
          },
        },
        {
          path: 'edit-application/claim-details',
          name: 'Claim Details',
          component: function () {
            return import(
              './views/pages/edit-application/claim/ClaimDetails.vue'
            );
          },
          meta: {
            breadcrumb: 'Claim Details',
          },
        },
        {
          path: 'edit-application/applicant',
          name: 'Applicant',
          component: function () {
            return import('./views/pages/edit-application/Applicant.vue');
          },
          meta: {
            breadcrumb: 'Applicant',
          },
        },
        {
          path: 'edit-application/charge-or-notation',
          name: 'Charge Or Notation',
          component: function () {
            return import(
              './views/pages/edit-application/ChargeOrNotation.vue'
            );
          },
          meta: {
            breadcrumb: 'Charge Or Notation',
          },
        },
        {
          path: 'edit-application/charges-liens-interests',
          name: 'Charges Liens Interests',
          component: function () {
            return import(
              './views/pages/edit-application/ChargesLiensInterests.vue'
            );
          },
          meta: {
            breadcrumb: 'Charges Liens Interests',
          },
        },
        {
          path: 'edit-application/declaration',
          name: 'Declaration',
          component: function () {
            return import('./views/pages/edit-application/Declaration.vue');
          },
          meta: {
            breadcrumb: 'Declaration',
          },
        },
        {
          path: 'edit-application/pid-legal-description',
          name: 'PID Legal Description',
          component: function () {
            return import(
              './views/pages/edit-application/PidLegalDescription.vue'
            );
          },
          meta: {
            breadcrumb: 'PID Legal Description',
          },
        },
        {
          path: 'edit-application/electronic-instrument',
          name: 'Electronic Instrument',
          component: function () {
            return import(
              './views/pages/edit-application/ElectronicInstrument.vue'
            );
          },
          meta: {
            breadcrumb: 'Electronic Instrument',
          },
        },
        {
          path: 'edit-application/execution',
          name: 'Execution',
          component: function () {
            return import('./views/pages/edit-application/Execution.vue');
          },
          meta: {
            breadcrumb: 'Execution',
          },
        },
        {
          path: 'edit-application/fee-simple',
          name: 'Fee Simple',
          component: function () {
            return import('./views/pages/edit-application/FeeSimple.vue');
          },
          meta: {
            breadcrumb: 'Fee Simple',
          },
        },
        {
          path: 'edit-application/form-validation',
          name: 'Form Validation',
          component: function () {
            return import('./views/pages/edit-application/FormValidation.vue');
          },
          meta: {
            breadcrumb: 'Form Validation',
          },
        },
        {
          path: 'edit-application/local-government',
          name: 'Local Government',
          component: function () {
            return import('./views/pages/edit-application/LocalGovernment.vue');
          },
          meta: {
            breadcrumb: 'Local Government',
          },
        },
        {
          path: 'edit-application/supporting-evidence',
          name: 'Supporting Evidence',
          component: function () {
            return import(
              './views/pages/edit-application/SupportingEvidence.vue'
            );
          },
          meta: {
            breadcrumb: 'Supporting Evidence',
          },
        },
        {
          path: 'edit-application/transfer-details',
          name: 'Transfer Details',
          component: function () {
            return import('./views/pages/edit-application/TransferDetails.vue');
          },
          meta: {
            breadcrumb: 'Transfer Details',
          },
        },
        {
          path: 'edit-application/transferor',
          name: 'Transferor',
          component: function () {
            return import('./views/pages/edit-application/Transferor.vue');
          },
          meta: {
            breadcrumb: 'Transferor',
          },
        },

        {
          path: 'mortgage-borrower',
          name: 'Mortgage Borrower',
          component: function () {
            return import(
              './views/pages/edit-application/mortgage-borrower/MortgageBorrower.vue'
            );
          },
          meta: {
            breadcrumb: 'Mortgage Borrower',
          },
        },
        {
          path: 'mortgage-terms',
          name: 'Mortgage Terms',
          component: function () {
            return import('./views/pages/edit-application/MortgageTerms.vue');
          },
          meta: {
            breadcrumb: 'Mortgage Terms',
          },
        },

        {
          path: 'edit-application/questionnaire',
          name: 'Questionnaire',
          component: function () {
            return import('./views/pages/ptt/Questionnaire.vue');
          },
          meta: {
            breadcrumb: 'Questionnaire',
          },
        },
        {
          path: 'edit-application/part-a-individual',
          name: 'Part A - individual',
          component: function () {
            return import('./views/pages/ptt/part-a/Individual.vue');
          },
          meta: {
            breadcrumb: 'Part A - individual',
          },
        },
        {
          path: 'edit-application/part-a-individual',
          name: 'Part A - corporation',
          component: function () {
            return import('./views/pages/ptt/part-a/Corporation.vue');
          },
          meta: {
            breadcrumb: 'Part A - corporation',
          },
        },
        {
          path: 'edit-application/part-e-terms',
          name: 'Part E - terms',
          component: function () {
            return import('./views/pages/ptt/part-e/PartETerms.vue');
          },
          meta: {
            breadcrumb: 'Part E - terms',
          },
        },
        {
          path: 'edit-application/part-h-calculation',
          name: 'Part H - calculation',
          component: function () {
            return import('./views/pages/ptt/PartHCalculation.vue');
          },
          meta: {
            breadcrumb: 'Part H - calculation',
          },
        },
        {
          path: 'edit-application/first-time-home-buyer',
          name: 'First Time Home Buyer',
          component: function () {
            return import('./views/pages/ptt/FirstTimeHomebuyer.vue');
          },
          meta: {
            breadcrumb: 'First Time Home Buyer',
          },
        },
        {
          path: 'edit-application/certification',
          name: 'Certification',
          component: function () {
            return import('./views/pages/ptt/Certification.vue');
          },
          meta: {
            breadcrumb: 'Certification',
          },
        },
      ],
    },

    {
      path: '/components',
      name: 'Components',
      component: function () {
        return import('./views/components/Components.vue');
      },
      meta: {
        breadcrumb: 'Components',
      },
      children: [
        {
          path: 'buttons',
          name: 'Buttons',
          component: function () {
            return import('./views/components/Buttons.vue');
          },
          meta: {
            breadcrumb: 'Buttons',
          },
        },
        {
          path: 'cards',
          name: 'Cards',
          component: function () {
            return import('./views/components/Cards.vue');
          },
          meta: {
            breadcrumb: 'Cards',
          },
        },
        {
          path: 'collapse',
          name: 'Collapse',
          component: function () {
            return import('./views/components/Collapse.vue');
          },
          meta: {
            breadcrumb: 'Collapse',
          },
        },
        {
          path: 'colours',
          name: 'Colours',
          component: function () {
            return import('./views/components/Colours.vue');
          },
          meta: {
            breadcrumb: 'Colours',
          },
        },
        {
          path: 'dialogs',
          name: 'dialogs',
          component: function () {
            return import('./views/components/Dialogs.vue');
          },
          meta: {
            breadcrumb: 'Dialogs',
          },
        },
        {
          path: 'feedback',
          name: 'Feedback',
          component: function () {
            return import('./views/components/Feedback.vue');
          },
          meta: {
            breadcrumb: 'Feedback',
          },
        },
        {
          path: 'forms',
          name: 'Forms',
          component: function () {
            return import('./views/components/Forms.vue');
          },
          meta: {
            breadcrumb: 'Forms',
          },
        },
        {
          path: 'help',
          name: 'Help',
          component: function () {
            return import('./views/components/Help.vue');
          },
          meta: {
            breadcrumb: 'Help',
          },
        },
        {
          path: 'icons',
          name: 'icons',
          component: function () {
            return import('./views/components/Icons.vue');
          },
          meta: {
            breadcrumb: 'Icons',
          },
        },
        {
          path: 'layout',
          name: 'layout',
          component: function () {
            return import('./views/components/Layout.vue');
          },
          meta: {
            breadcrumb: 'Layout',
          },
        },
        {
          path: 'navigation',
          name: 'navigation',
          component: function () {
            return import('./views/components/Navigation.vue');
          },
          meta: {
            breadcrumb: 'Navigation',
          },
        },
        {
          path: 'page-headers',
          name: 'Page headers',
          component: function () {
            return import('./views/components/PageHeaders.vue');
          },
          meta: {
            breadcrumb: 'Page Headers',
          },
        },
        {
          path: 'step-wizard',
          name: 'Step Wizard',
          component: function () {
            return import('./views/components/StepWizard.vue');
          },
          meta: {
            breadcrumb: 'Step Wizard',
          },
        },
        {
          path: 'tables',
          name: 'Tables',
          component: function () {
            return import('./views/components/Tables.vue');
          },
          meta: {
            breadcrumb: 'Tables',
          },
        },
        {
          path: 'tabs',
          name: 'tabs',
          component: function () {
            return import('./views/components/Tabs.vue');
          },
          meta: {
            breadcrumb: 'Tabs',
          },
        },
        {
          path: 'typography',
          name: 'Typography',
          component: function () {
            return import('./views/components/Typography.vue');
          },
          meta: {
            breadcrumb: 'Typography',
          },
        },
        {
          path: 'utilities',
          name: 'Utilities',
          component: function () {
            return import('./views/components/Utilities.vue');
          },
          meta: {
            breadcrumb: 'Utilities',
          },
        },
      ],
    },
  ],
});

export default router;
